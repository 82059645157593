import React, { useState, useEffect, useMemo } from "react";
import loadable from "@loadable/component";
import { useAppState } from "@state";
import classNames from "classnames";
import useLang from "@hooks/useLang";

const DefaultPageContainer = loadable(() =>
  import("@templates/DefaultPageContainer")
);
const IntentionIntro = loadable(() => import("@organisms/IntentionIntro"));
const IntentionCardsContainer = loadable(() =>
  import("@organisms/IntentionCardsContainer")
);

const HomePage = ({ data }) => {
  const { intro, intentions } = data;
  // animate state for card section
  const [sectionOpen, setSectionOpen] = useState(false);

  const [{ modal: modalState }, dispatch] = useAppState();
  const { modal: open } = modalState;

  const lang = useLang();

  useEffect(() => {
    setSectionOpen(false);

    dispatch({ type: "hideIntentionCards" });
  }, [lang]);

  const shuffle = array => {
    // eslint-disable-next-line no-plusplus
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      // eslint-disable-next-line no-param-reassign
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // make sure the array is memo'd so things don't reshuffle
  const shuffledArray = useMemo(() => shuffle(intentions?.cards), []);

  return (
    <DefaultPageContainer>
      <article
        className={classNames("transition duration-500", {
          "opacity-0": open,
        })}
      >
        <IntentionIntro
          {...intro}
          cards={shuffledArray.filter((c, i) => i < 4)}
          lang={lang}
          sectionState={[sectionOpen, setSectionOpen]}
        />
        <IntentionCardsContainer
          {...intentions}
          cards={shuffledArray}
          sectionState={[sectionOpen, setSectionOpen]}
        />
      </article>
    </DefaultPageContainer>
  );
};

HomePage.defaultProps = {};

export default HomePage;
