import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "@pageTemplates/IntentionsHomePage";
import resolveImage from "@dataResolvers/resolveImage";
// eslint-disable-next-line no-unused-vars
import ImageFragment from "@fragments/ImageFragment";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query IntentionsHomeQuery($siteId: [Craft_QueryArgument]) {
    craft {
      entry(section: "intentions", siteId: $siteId) {
        ... on Craft_intentions_intentions_Entry {
          title
          metaTitle
          metaDescription
          metaImage {
            ...ImageFragment
          }
          # intro
          label0
          heading0
          descriptor0
          list0 {
            ... on Craft_list0_TableRow {
              text: col1
            }
          }
          heading1
        }
      }
      entries(section: "intentionCards", siteId: $siteId) {
        ... on Craft_intentionCards_default_Entry {
          uid
          title
          image0 {
            ...ImageFragment
          }
          image1 {
            ...ImageFragment
          }
          artist {
            ... on Craft_artist_default_Entry {
              title
              headshot: image0 {
                ...ImageFragment
              }
              bio: descriptor0
              link: link0 {
                text
                url
              }
              socials {
                url
                platform
              }
              colorPalette {
                cardText
                cardBackground
              }
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry, entries }) => {
  const {
    title,
    metaImage,
    // intro
    label0,
    heading0,
    descriptor0,
    list0,
    // intentions
    heading1,
    metaTitle,
    metaDescription,
  } = entry;

  return {
    meta: {
      title,
      metaFeaturedImage: resolveImage(metaImage),
      metaPageTitle: metaTitle,
      metaDescription,
    },
    intro: {
      label: label0,
      heading: heading0,
      description: descriptor0,
      steps: list0?.map(col => col?.text),
    },
    intentions: {
      heading: heading1,
      cards: entries?.map((e, i) => {
        const palette = e?.artist?.[0]?.colorPalette;

        const color =
          palette[Math.floor(Math.random() * palette.length)].cardText;
        const backgroundColor =
          palette[Math.floor(Math.random() * palette.length)].cardBackground;
        return {
          ...e,
          artwork: resolveImage(e?.image0),
          background: resolveImage(e?.image1),
          artist: {
            ...e?.artist?.[0],
            headshot: resolveImage(e?.artist?.[0]?.headshot),
          },
          color,
          backgroundColor,
          cardIndex: i,
        };
      }),
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="IntentionsQuery"
    {...props}
  />
);

export default Page;
